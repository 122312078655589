.footer {
  padding: var(--section-vertical-padding);

  .container {
    display: flex;

    flex-direction: column-reverse;

    padding: 30px;

    border-top: 1px solid rgba(255, 255, 255, 0.14);

    .column {
      font-size: var(--font-size-tiny);
      color: var(--color-white2);
      text-align: center;

      &:not(:last-child) {
        margin-top: 20px;
      }

      &.brand {
        :not(:first-child) {
          margin-top: 15px;
        }

        .logo {
          height: 16px;
        }

        .notice {
          font-weight: 400;
        }

        .copyright {
          font-weight: 600;
        }
      }

      &.menu {
        display: flex;

        flex-direction: column;

        align-items: center;

        nav {
          display: grid;

          grid-template-columns: repeat(3, 1fr);
          grid-row-gap: 10px;

          grid-column-gap: 20px;

          width: 100%;

          a {
            font-weight: 500;
            color: var(--color-white2);
            text-align: center;
            text-decoration: none;

            &[data-focus-visible-added],
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .social {
          display: flex;

          flex-direction: row;

          align-items: center;

          .socialLink {
            svg {
              display: block;

              height: 24px;

              fill: var(--color-white2);

              transition: all 150ms ease;

              circle {
                fill: var(--color-white2);

                transition: all 150ms ease;
              }
            }

            &:not(:first-child) {
              margin-left: 12px;
            }

            &[data-focus-visible-added], &:hover {
              svg {
                fill: var(--color-white);

                circle {
                  fill: var(--color-white);
                }
              }
            }
          }
        }

        nav + .social {
          margin-top: 15px;
        }
      }
    }
  }
}

@media (--min-md) {
  .footer {
    .container {
      flex-direction: row;

      align-items: flex-start;

      .column {
        &:not(:last-child) {
          margin-top: 0;
        }

        &.brand {
          width: 33%;

          text-align: left;
        }

        &.menu {
          align-items: flex-start;

          width: 50%;

          margin-left: 90px;

          nav + .social {
            margin-top: 40px;
          }

          nav {
            a {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

@media (--min-lg) {
  .footer {
    .container {
      .column {
        &.menu {
          width: 33%;
        }
      }
    }
  }
}
