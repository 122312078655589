.withdrawAmountSelection {
  display: grid;

  grid-gap: 16px;

  .header {
    display: grid;

    grid-gap: 8px;

    .title {
      font-weight: 700;
    }

    .muted {
      font-size: var(--font-size);
      font-weight: 600;
      color: var(--color-gray);
    }
  }

  .input {
    display: grid;

    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 20px;

    background-color: var(--color-dark-gray);
    border-radius: 7px;

    .button {
      display: flex;

      align-items: center;
      justify-content: center;

      cursor: pointer;

      &[data-focus-visible-added], &:hover {
        svg {
          filter: drop-shadow(0px 2px 4px var(--color-gray));
        }
      }

      svg {
        display: block;

        width: 24px;
        height: 100%;
      }
    }

    .amountInput {
      input {
        text-align: center;
      }
    }
  }

  .maxButton {
    font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--color-gray);

    cursor: pointer;

    &[data-focus-visible-added], &:hover {
      text-decoration: underline;
    }
  }
}
