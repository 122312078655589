.signUpContainer {
  position: relative;

  overflow: hidden;

  line-height: 1.2;

  .background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    height: 502px;

    background: url('./background.png') no-repeat top center;
    background-size: cover;

    &.overlay {
      background-image: linear-gradient(to bottom, rgba(17, 18, 23, 0.2) 80%, #111217 100%);
    }
  }

  .container {
    .col {
      z-index: 2;

      display: grid;

      grid-auto-flow: row;
      grid-gap: 30px;

      align-self: center;

      &:first-child {
        margin: 30px 0;
      }

      &:last-child {
        max-width: 540px;
      }

      .main {
        text-align: center;

        .title {
          margin-bottom: 15px;

          font-size: var(--font-size-headline2);
          font-weight: 700;
        }

        .subTitle {
          max-width: 453px;
          margin: 0 auto;

          font-size: var(--font-size);
          font-weight: 600;

          color: var(--color-white2);
        }
      }

      .info {
        display: grid;

        grid-template-columns: repeat(3, auto);
        grid-gap: 30px;

        .details {
          display: flex;

          flex-direction: column;

          text-align: center;

          .icon {
            align-self: center;

            max-width: 50px;
            margin: 0 0 15px 0;

            svg {
              display: block;

              width: 100%;

              height: 50px;
            }
          }

          .detail {
            display: grid;

            grid-template-columns: 1fr;
            grid-gap: 6px;

            .title {
              font-size: var(--font-size);
              font-weight: 700;
            }

            .content {
              display: none;

              font-size: var(--font-size-small);
              font-weight: 600;

              color: var(--color-gray);
            }
          }
        }
      }
    }
  }
}

@media (--min-sm) {
  .signUpContainer {
    padding: 30px 0;

    .container {
      .col {
        .info {
          grid-gap: 35px;

          .details {
            .detail {
              .content {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}

@media (--min-lg) {
  .signUpContainer {
    padding: 120px 0 40px 0;

    .container {
      flex-direction: row;

      justify-content: space-between;

      .col {
        align-self: flex-start;

        width: 50%;

        &:first-child {
          max-width: 385px;
          padding: 0;
          margin: 0;
        }

        .main {
          grid-gap: 15px;

          text-align: left;

          .title {
            font-size: var(--font-size-headline1);
          }

          .subTitle {
            font-size: var(--font-size-small);
            font-weight: 500;
            color: var(--color-gray);
          }
        }

        .info {
          grid-template-columns: 1fr;
          grid-gap: 25px;

          .details {
            flex-direction: row;

            color: var(--color-gray);
            text-align: left;

            .icon {
              align-self: flex-start;

              margin: 0 40px 0 0;
            }

            .detail {
              .title {
                font-weight: 700;
                color: var(--color-white);
              }

              .content {
                max-width: 235px;
              }
            }
          }
        }
      }
    }
  }
}
