.userDuelRivals {
  .body {
    display: grid;

    grid-gap: 8px;

    .showMoreButtonWrapper {
      display: flex;

      justify-content: center;

      margin: 16px 0 0 0;
    }
  }
}
