.settingsSectionTitle {
  display: flex;

  align-items: center;

  padding-bottom: 15px;

  font-weight: bold;

  border-bottom: 1px solid var(--color-white2);

  .goBack {
    padding: 10px;
    margin-right: 16px;

    cursor: pointer;

    border-radius: 8px;

    svg {
      display: block;

      width: 24px;
      height: 24px;
    }

    &[data-focus-visible-added], &:hover {
      background-color: var(--color-dark-gray2);
    }
  }
}
