.leaderboardTournamentRules {
  background-color: var(--color-black3);
  border-radius: 8px;

  .header {
    padding: 12px 8px;

    font-weight: 700;
    color: var(--color-white);

    background-color: var(--color-dark-gray);

    border-radius: 8px 8px 0 0;

    transition: padding 150ms ease;
  }

  .body {
    padding: 24px 8px;

    transition: padding 150ms ease;

    .markdown {
      li {
        &:before {
          color: var(--color-white);
        }
      }
    }
  }
}

@media (--min-xs) {
  .leaderboardTournamentRules {
    .header {
      padding: 12px 32px;
    }

    .body {
      padding: 24px;
    }
  }
}
