.onlineUsers {
  .headerButton {
    position: relative;

    svg {
      display: block;

      height: 24px;
    }

    .onlineIndicator {
      position: absolute;
      right: 25%;
      bottom: 25%;
      z-index: 2;

      width: 12px;

      height: 12px;

      background-color: var(--color-green);

      border-radius: 50%;

      transform: translate(50%, 50%);
    }
  }

  .onlineUsersSideNav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 11;

    width: 270px;

    min-height: 100%;
    padding: 0 16px 16px 16px;
    overflow-x: hidden;
    overflow-y: scroll;

    background-color: var(--color-black3);
    box-shadow: -4px 2px 16px 0 rgba(0, 0, 0, 0.5);

    transition: right 300ms ease, width 300ms ease;

    .wrapper {
      position: relative;

      .toggle {
        position: absolute;
        top: 44px;
        left: -38px;
        z-index: 10;

        display: flex;

        width: 28px;

        height: 36px;

        background-color: var(--color-black3);

        border-radius: 3px 0 0 3px;

        .toggleButton {
          display: flex;

          flex: 1;

          align-items: center;
          justify-content: center;

          background-color: var(--color-dark-gray2);

          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;

          svg {
            display: block;

            height: 20px;

            transition: all 150ms ease;

            transform: rotate(180deg);
          }

          &[data-transition-focus-added], &:hover {
            svg {
              filter: drop-shadow(0 0 2px var(--color-red));
            }
          }
        }
      }

      .mainTitle {
        display: flex;

        justify-content: space-between;

        margin: 20px 0 0 8px;

        font-weight: 700;
      }

      .filters {
        margin: 14px 0;

        .isShownWhenOnline {
          flex-direction: row-reverse;

          > * {
            padding: 0;

            font-size: var(--font-size-small);
            font-weight: 600;
            line-height: 0.75;
            color: var(--color-gray);
            text-align: left;
          }
        }

        .filter {
          overflow: hidden;

          border-collapse: collapse;

          /* TODO: color not found in the design system */
          border-color: #494b58;
          border-style: solid;

          border-width: 1px 1px 0 1px;

          &:first-child {
            border-radius: 5px 5px 0 0;
          }

          &:last-child {
            border-bottom-width: 1px;
            border-radius: 0 0 5px 5px;
          }

          .title {
            display: flex;

            align-items: center;
            justify-content: space-between;

            width: 100%;
            padding: 16px 8px;

            margin: 0;

            font-size: var(--font-size-small);
            font-weight: 600;
            line-height: 0.75;

            color: var(--color-gray);
            text-align: left;

            &.accordionButton {
              &[data-focus-visible-added],
              &:hover {
                background-color: var(--color-dark-gray);
              }
            }

            .name {
              display: flex;

              flex: 1;

              justify-content: space-between;

              margin-right: 10px;

              span {
                color: var(--color-white);
              }
            }

            svg {
              display: block;

              height: 6px;

              transition: transform var(--transition-duration) ease;

              &.opened {
                transform: rotate(180deg);
              }
            }
          }

          .content {
            padding: 0 8px;

            &.inline {
              display: flex;

              flex-wrap: wrap;

              justify-content: space-between;
            }

            .formGroup {
              margin: 8px 0;

              label {
                width: 100%;
              }
            }
          }
        }
      }

      .clearFilters {
        font-size: var(--font-size-small);
        font-weight: 500;
        color: var(--color-gray);
        text-decoration: none;

        cursor: pointer;

        transition: color 150ms ease, text-decoration 150ms ease;

        &[data-transition-focus-added], &:hover {
          color: var(--color-white);
          text-decoration: underline;
        }
      }

      .body {
        margin: 20px 0;

        .header {
          display: grid;

          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px;

          justify-content: space-between;

          margin-bottom: 16px;

          font-size: var(--font-size-small);
          font-weight: 600;

          color: var(--color-gray);

          :last-child {
            text-align: right;
          }
        }

        .users {
          display: grid;

          grid-template-columns: 1fr;
          grid-gap: 10px;
        }
      }

      .showMoreButtonWrapper {
        text-align: center;
      }
    }

    &.open {
      .wrapper {
        .toggle {
          .toggleButton {
            background-color: var(--color-black3);

            svg {
              transform: none;
            }
          }
        }
      }
    }

    &:global {
      &.enter,
      &.exit.exit-active,
      &.exit-done {
        right: -300px;
      }

      &.enter.enter-active,
      &.exit {
        right: 0;
      }
    }
  }
}

@media (--min-xs) {
  .onlineUsers {
    .onlineUsersSideNav {
      width: 300px;
    }
  }
}
