.avatar {
  position: relative;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: flex-start;

  .duelCount {
    position: absolute;
    right: 16%;
    bottom: 16%;
    z-index: 2;

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    min-width: 30px;
    min-height: 30px;

    font-size: var(--font-size-small);
    font-weight: 700;
    color: var(--color-white);
    text-align: center;

    background-color: var(--color-dark-gray);
    border: solid 1px var(--color-red);
    border-radius: 50%;

    transform: translate(50%, 50%);

    .muted {
      font-weight: 300;
    }
  }

  .flag {
    position: absolute;
    bottom: -4px;

    display: block;

    width: 24px;
    height: 16px;
  }

  .banned {
    position: absolute;

    right: 16%;
    bottom: 16%;

    z-index: 2;

    display: block;

    width: 30%;
    height: 30%;

    transform: translate(50%, 50%);

    &.withRank {
      top: -10%;
      right: 16%;
      bottom: auto;
    }
  }

  .blocked {
    position: absolute;

    right: 16%;
    bottom: 16%;

    z-index: 2;

    display: block;

    width: 20px;
    height: 20px;

    transform: translate(50%, 50%);
  }

  .rank {
    position: absolute;
    right: 0;
    bottom: -10px;
    left: 0;

    display: flex;

    align-items: center;
    justify-content: center;

    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-white2);

    background-color: var(--color-black);
    border: solid 1px transparent;
    border-radius: 10px;

    &.bronzeRankBorder {
      border-color: var(--color-bronze);
    }

    &.goldRankBorder {
      border-color: var(--color-gold);
    }

    &.redRankBorder {
      border-color: var(--color-red);
    }

    &.silverRankBorder {
      border-color: var(--color-silver);
    }
  }

  .onlineIndicator {
    position: absolute;
    right: 16%;
    bottom: 16%;
    z-index: 2;

    background-color: var(--color-green);
    border-radius: 50%;

    transform: translate(50%, 50%);
  }

  &.banned {
    .image {
      opacity: 0.5;
    }
  }

  &.withFlag {
    margin-bottom: 8px;
  }

  .image {
    display: block;

    width: 100%;

    height: 100%;

    border-radius: 50%;

    &.canceled, &.declined {
      border: 1px solid var(--color-red);
    }

    &.ended, &.expired {
      border: 1px solid var(--color-gray);
    }

    &.in_progress {
      border: 1px solid var(--color-orange);
    }

    &.open, &.invited {
      border: 1px solid var(--color-green);
    }

    &.checking_in, &.declaring_result {
      border: 1px solid var(--color-yellow);
    }
  }
}
