.toastsHandler {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 100;

  width: 100%;

  pointer-events: none;

  transform: translateX(-50%);

  .toast {
    margin: 10px 0;

    pointer-events: all;
  }
}
