.settingsNotifications {
  .muted {
    margin: 24px 0;

    font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--color-gray);
  }
}

.link {
  color: var(--color-white);

  transition: text-decoration var(--transition-duration) ease;

  &[data-focus-visible-added], &:hover {
    text-decoration: underline;
  }
}
