.chatMessage {
  display: flex;

  flex-direction: column;

  align-items: flex-start;

  &.own {
    align-items: flex-end;

    .user {
      flex-direction: row-reverse;

      transform-origin: right center;

      .username {
        margin: 0 8px 0 0;
      }
    }

    .message {
      margin: 0 38px 0 0;

      text-align: right;

      .messageContent {
        background-color: var(--color-green2);
      }
    }
  }

  .user {
    display: inline-flex;

    flex-direction: row;

    align-items: center;

    text-decoration: none;

    transition: transform 100ms ease;
    transform-origin: left center;

    &[data-focus-visible-added], &:hover {
      color: var(--color-white);

      transform: scale(1.3);
    }

    .username {
      margin: 0 0 0 8px;

      font-size: var(--font-size-smaller);
      font-weight: 600;

      color: var(--color-gray);
    }
  }

  .message {
    margin: 0 0 0 38px;

    .messageContent {
      display: inline-block;

      flex: 0 0 auto;

      padding: 10px 16px;

      background-color: var(--color-dark-gray2);

      border-radius: 16px;
    }

    .messageDateTime {
      margin-top: 6px;

      font-size: var(--font-size-smaller);
      font-weight: 500;

      color: var(--color-gray);
    }
  }
}
