.duelCard {
  display: grid;

  .wrapper {
    display: grid;

    grid-template-rows: auto 1fr;
    grid-auto-flow: row;

    font-size: var(--font-size-small);

    color: var(--color-white);
    text-decoration: none;

    background-color: var(--color-black3);

    border-radius: 8px;

    transition: background-color var(--transition-duration) ease;

    &[data-focus-visible-added], &:hover {
      background-color: var(--color-dark-gray2);
    }

    .header {
      height: 48px;

      background-color: var(--color-dark-gray);

      border-radius: 8px 8px 0 0;

      .headerWrapper {
        display: flex;

        align-items: center;
        justify-content: space-between;

        height: 100%;

        padding: 6px 12px;

        .game {
          display: grid;

          grid-auto-flow: column;
          grid-gap: 10px;

          align-items: center;

          .duelGame {
            font-size: var(--font-size-small);
            font-weight: 700;
          }
        }
      }
    }

    .content {
      display: grid;

      grid-template-rows: 1fr auto;
      grid-row-gap: 20px;

      padding: 16px 12px;

      .opponents {
        display: grid;

        grid-template-columns: 1fr 36px 1fr;
        grid-gap: 20px;

        align-items: flex-start;
        justify-items: flex-start;

        .player {
          font-size: var(--font-size);
          font-weight: 700;
          color: var(--color-white);

          .lost {
            border-bottom: 1px solid var(--color-red);
          }

          .won {
            border-bottom: 1px solid var(--color-green);
          }

          &.waiting {
            min-width: 0;

            max-width: 108px;
            padding: 3px 8px;

            font-size: var(--font-size-small);
            font-weight: 600;
            line-height: 1.07;

            color: var(--color-gray);
            text-align: center;

            border: dashed 1px var(--color-gray);

            border-radius: 18px;
          }
        }

        .vs {
          justify-self: center;

          padding: 1px 16px;
        }

        .player2 {
          justify-self: flex-end;

          text-align: right;
        }
      }

      .gameDetails {
        display: grid;

        grid-auto-flow: column;
        grid-gap: 26px;

        align-items: center;

        font-size: var(--font-size-small);
        font-weight: 600;

        color: var(--color-gray);

        .coins, .gameMode {
          display: flex;

          align-items: center;

          svg {
            display: block;

            height: 15px;

            margin-right: 5px;
          }
        }
      }
    }

    &.expand {
      .header {
        height: 120px;

        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;

        .headerWrapper {
          align-items: flex-start;

          padding: 15px 8px;
        }
      }
    }
  }
}
