.boxLabel {
  position: absolute;

  top: 50%;
  left: var(--form-component-horizontal-padding);

  margin-left: 2px;

  font-size: var(--font-size-small);
  font-weight: 600;

  color: var(--color-gray);

  pointer-events: none;

  transition: top 150ms ease, transform 150ms ease, font-size 150ms ease;

  transform: translateY(-50%);

  /* sizes */
  &.sizeLarge {
    font-size: var(--font-size);
  }

  &.sizeMedium {
    font-size: var(--font-size-small);
  }

  &.sizeSmall {
    font-size: var(--font-size-small);
  }

  &.onTop {
    top: 5px;

    font-size: var(--font-size-tiny);
    font-weight: 700;

    transform: translateY(0);
  }
}
