.duelChat {
  display: flex;

  flex-direction: column;

  &.expanded {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    height: 100vh;

    &&& {
      margin: 0;
    }

    .body {
      .content {
        flex: 1;

        max-height: none;
      }
    }
  }

  .header {
    display: flex;

    flex: 0 0 auto;

    flex-direction: row;

    align-items: center;

    height: 48px;

    padding: 0 12px;

    background-color: var(--color-dark-gray);

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .headerSide {
      flex: 1;

      .headerButton {
        display: block;

        width: 32px;
        height: 32px;

        padding: 4px;

        cursor: pointer;

        border-radius: 4px;

        transition: background-color 100ms ease;

        &[data-focus-visible-added], &:hover {
          background-color: var(--color-dark-gray3);

          svg {
            fill: var(--color-white);
          }
        }

        svg {
          display: block;

          width: 100%;
          height: 100%;

          fill: var(--color-gray);

          transition: fill 100ms ease;
        }
      }

      &.headerRight {
        display: flex;

        flex-direction: row;

        justify-content: flex-end;
      }
    }

    .title {
      padding: 0 10px;

      font-size: var(--font-size-big);
      font-weight: 700;
      text-align: center;
    }
  }

  .body {
    display: flex;

    flex: 1 1 auto;
    flex-direction: column;

    min-height: 0;

    background-color: var(--color-black3);

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    > * {
      &:not(:last-child) {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    .content {
      display: flex;

      flex-direction: column;

      max-height: 240px;

      padding: 24px 12px;

      overflow-y: auto;

      > * {
        &:not(:first-child) {
          margin-top: 30px;
        }
      }
    }

    .input {
      padding: 12px;

      border-top: 1px solid var(--color-dark-gray2);

      .form {
        display: grid;

        grid-template-columns: 1fr auto;
        grid-gap: 8px;
      }
    }
  }
}
