.button {
  display: inline-flex;

  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding: 0 var(--button-horizontal-padding);

  font-size: var(--font-size-small);
  font-weight: 700;

  color: var(--color-white);
  text-decoration: none;
  white-space: nowrap;

  cursor: pointer;

  border-radius: var(--form-component-border-radius);

  transition: background-color 150ms ease, border-color 150ms ease;

  &.fullWidth {
    width: 100%;
  }

  &.iconOnly {
    padding: 0 16px;

    svg {
      display: block;

      height: 40%;

      fill: var(--color-gray);
    }
  }

  &:disabled {
    && {
      color: var(--color-dark-gray3);

      cursor: not-allowed;

      background-color: var(--color-dark-gray);
    }
  }

  /* sizes */
  &.sizeLarge {
    height: var(--form-component-large-height);

    font-size: var(--font-size);
  }

  &.sizeMedium {
    height: var(--form-component-medium-height);
  }

  &.sizeSmall {
    height: var(--form-component-small-height);
  }

  /* variants */
  &.variantPrimary {
    background-color: var(--color-red);
    border: 2px solid transparent;

    &:not(:disabled) {
      &[data-focus-visible-added], &:hover {
        background-color: var(--color-black2);
        border-color: var(--color-red);
      }
    }

    &.outline {
      background-color: transparent;
      border: 2px solid var(--color-red);

      &[data-focus-visible-added], &:hover {
        background-color: var(--color-red);
      }
    }
  }

  &.variantSecondary {
    background-color: var(--color-dark-gray2);
    border: 2px solid transparent;

    &:not(:disabled) {
      &[data-focus-visible-added], &:hover {
        background-color: var(--color-black2);
        border-color: var(--color-dark-gray2);
      }
    }

    &.outline {
      background-color: var(--color-black2);
      border: 2px solid var(--color-dark-gray2);

      &[data-focus-visible-added], &:hover {
        background-color: var(--color-dark-gray);
        border-color: var(--color-dark-gray);
      }
    }
  }

  &.variantGreen {
    background-color: var(--color-green2);
    border: 2px solid transparent;

    &:not(:disabled) {
      &[data-focus-visible-added], &:hover {
        background-color: var(--color-black2);
        border-color: var(--color-green2);
      }
    }
  }
}

@media (--min-xs) {
  .button {
    padding: 0 var(--button-horizontal-padding-xs);
  }
}
