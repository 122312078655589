.userTrophies {
  background-color: var(--color-black3);
  border-radius: 8px;

  .header {
    padding: 10px 16px;

    font-weight: bold;
    color: var(--color-white);

    background-color: var(--color-dark-gray);
    border-radius: 8px 8px 0 0;
  }

  .wrapper {
    padding: 14px 12px;

    .trophiesHeader {
      display: grid;

      grid-template-columns: 1fr 3fr 1fr;
      grid-gap: 30px;

      font-size: var(--font-size);
      font-weight: 500;
      color: var(--color-gray);
    }

    .separator {
      margin: 10px 0 18px 0;

      border: solid 1px var(--color-gray);
      opacity: 0.4;
    }

    .trophies {
      display: grid;

      grid-template-columns: 1fr;

      .trophy {
        display: grid;

        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 30px;

        padding: 12px 0;

        text-decoration: none;

        border-radius: 8px;

        transition: background-color 150ms ease;

        &[data-focus-visible-added], &:hover {
          background-color: var(--color-dark-gray2);
        }

        .ranking {
          display: flex;

          align-items: center;
          justify-content: center;

          .rank {
            display: flex;

            align-items: center;
            justify-content: center;

            width: 48px;
            height: 20px;

            font-size: var(--font-size-small);
            font-weight: 600;
            color: var(--color-white2);

            text-align: center;

            background-color: var(--color-black);
            border: solid 1px var(--color-red);
            border-radius: 10px;
          }
        }

        .game {
          display: flex;

          align-items: center;

          .name {
            margin-left: 10px;

            font-size: var(--font-size);
            font-weight: 600;
            color: var(--color-white);
          }
        }

        .reward {
          display: flex;

          align-items: center;

          font-size: var(--font-size-small);
          font-weight: 700;
          color: var(--color-white);

          svg {
            display: block;

            width: 22px;
            height: 22px;

            margin-left: 5px;
          }
        }
      }
    }
  }
}
