.settingsSidebar {
  display: grid;

  grid-gap: 40px;

  .wrapper {
    display: grid;

    grid-gap: 16px;

    .title {
      padding-left: 28px;

      font-size: var(--color-white);
      font-weight: 700;
    }
  }
}
