.scrollIndicator {
  display: none;

  .container {
    align-items: center;
    justify-content: center;

    .section {
      margin: 30px 0;

      .arrow {
        width: 43px;

        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));

        opacity: 0.3;

        fill: var(--color-gray);
      }

      .mouse {
        width: 29px;
      }
    }
  }
}

@media (--min-lg) {
  .scrollIndicator {
    display: block;
  }
}
