.userInput {
  position: relative;

  /* size */
  &.sizeLarge {
    .choices {
      .choiceButton {
        height: var(--form-component-large-height);
      }
    }

    .value {
      height: var(--form-component-large-height);

      font-size: var(--font-size);
    }
  }

  &.sizeMedium {
    .choices {
      .choiceButton {
        height: var(--form-component-medium-height);
      }
    }

    .value {
      height: var(--form-component-medium-height);

      font-size: var(--font-size-small);
    }
  }

  &.sizeSmall {
    .choices {
      .choiceButton {
        height: var(--form-component-small-height);
      }
    }

    .value {
      height: var(--form-component-small-height);

      font-size: var(--font-size-smaller);
    }
  }

  .choices {
    position: absolute;

    top: 100%;
    z-index: 11;

    width: 100%;
    max-height: 200px;

    overflow: auto;

    background-color: var(--color-black);

    .choiceButton {
      display: flex;

      flex-direction: row;

      align-items: center;

      width: 100%;

      padding: 0 var(--form-component-horizontal-padding);

      cursor: pointer;

      &[data-focus-visible-added], &:hover {
        background-color: var(--color-dark-gray2);
      }

      .username {
        margin-left: 6px;

        font-size: var(--font-size);

        color: var(--color-white);
      }
    }
  }

  .value {
    display: flex;

    flex-direction: row;

    align-items: center;

    padding: 0 var(--form-component-horizontal-padding);

    border: 2px solid var(--color-dark-gray2);
    border-radius: var(--form-component-border-radius);

    .valueUsername {
      margin-left: 6px;

      font-weight: 700;
    }

    .valueActions {
      margin-left: auto;

      .valueEditButton {
        height: 30px;

        padding: 6px;

        cursor: pointer;

        border-radius: 4px;

        &[data-focus-visible-added], &:hover {
          background-color: var(--color-dark-gray4);
        }

        svg {
          display: block;

          height: 100%;

          fill: var(--color-white);
        }
      }
    }
  }
}
