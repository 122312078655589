.duelInProgress {
  display: grid;

  grid-gap: 15px;

  padding: 16px 38px;

  background-color: var(--color-black3);
  border-radius: 8px;

  .message {
    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-gray);
    text-align: center;
  }
}
