.toast {
  display: flex;

  flex-direction: row;

  align-items: center;

  padding: 10px 14px;

  color: var(--color-white);

  border-radius: 8px;

  transition: all 300ms ease;

  &:global {
    &.enter, &.exit.exit-active {
      opacity: 0;

      transform: translate(0, -100%);
    }

    &.enter.enter-active, &.exit {
      opacity: 1;

      transform: none;
    }
  }

  /* kinds */
  &.kindSuccess {
    background-color: var(--color-green);
  }

  &.kindWarning {
    background-color: var(--color-orange);
  }

  .icon {
    margin-right: 8px;

    svg {
      display: block;

      height: 18px;
    }
  }

  .content {
    font-size: var(--font-size-small);
    font-weight: 700;
  }

  .separator {
    flex: 1;

    min-width: 10px;
  }

  .dismissButton {
    display: block;

    padding: 4px;

    cursor: pointer;

    border-radius: 4px;

    transition: background-color var(--transition-duration) ease;

    &[data-focus-visible-added], &:hover {
      background-color: var(--color-white3);
    }

    svg {
      display: block;

      height: 18px;
    }
  }
}
