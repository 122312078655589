.sideTabsSectionTitle {
  padding: 16px 16px 16px 28px;

  font-size: var(--color-white);
  font-weight: 700;

  &:not(:first-child) {
    margin-top: 14px;
  }
}
