.chatNotice {
  font-size: var(--font-size-small);
  font-weight: 500;

  color: var(--color-gray);
  text-align: center;

  .content {
    display: inline-block;

    min-width: 80%;

    padding: 8px 20px;

    font-size: var(--font-size);
    font-weight: 600;

    background-color: var(--color-dark-gray2);

    border-radius: 18px;

    a {
      color: inherit;
      text-decoration: underline;

      transition: color 100ms ease;

      &[data-focus-visible-added], &:hover {
        color: var(--color-white);
      }
    }
  }

  .dateTime {
    margin-top: 6px;

    font-size: var(--font-size-smaller);
    font-weight: 500;
  }
}
