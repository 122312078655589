.sectionCard {
  &.collapsible {
    &.open {
      .header {
        border-radius: 8px 8px 0 0;

        svg {
          width: 18px;
          height: 18px;

          transform: rotate(45deg);
        }
      }
    }

    .header {
      cursor: pointer;

      border-radius: 8px;

      transition: background-color 100ms ease;

      svg {
        width: 17px;
        height: 17px;

        margin-left: 30px;

        fill: var(--color-gray);

        transition: transform 150ms ease;

        transform: rotate(0);
      }

      &[data-focus-visible-added], &:hover, &:active {
        background-color: var(--color-dark-gray2);
      }
    }
  }

  &.noTitle {
    .wrapper {
      border-radius: 8px;
    }
  }

  .header {
    display: flex;

    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 14px 16px;

    font-size: var(--font-size);
    font-weight: 700;

    color: var(--color-white);
    text-align: left;

    background-color: var(--color-dark-gray);
    border-radius: 8px 8px 0 0;
  }

  .wrapper {
    padding: 24px 16px 32px 16px;

    background-color: var(--color-black3);
    border-radius: 0 0 8px 8px;
  }
}
