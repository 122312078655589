.leaderboardTournamentHeader {
  margin-bottom: 64px;

  transition: margin-bottom var(--transition-duration) ease;

  .header {
    display: flex;

    min-height: 200px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .headerBody {
      display: flex;

      flex-direction: column;

      justify-content: space-between;

      min-width: 100%;

      background-image: linear-gradient(to bottom, rgba(17, 18, 23, 0.2), var(--color-black) 100%);

      .share {
        display: flex;

        justify-content: flex-end;

        padding: 15px;
      }

      .container {
        margin-bottom: -64px;

        transition: margin-bottom var(--transition-duration) ease;

        .game {
          display: flex;

          justify-content: flex-start;

          img {
            display: block;

            width: 90px;
            height: 90px;

            margin-right: 16px;

            border-radius: 12px;
          }

          .info {
            display: flex;

            flex-direction: column;

            justify-content: space-between;

            .name {
              display: flex;

              flex: 1;

              max-width: 50vw;

              margin: 4px 0 0 6px;
              overflow: hidden;

              text-overflow: ellipsis;
              overflow-wrap: break-word;

              transition: max-width 150ms ease;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }

        .countdownWrapper {
          display: flex;

          align-items: center;
          justify-content: flex-start;

          margin-top: 30px;

          .countdown {
            display: grid;

            grid-template-columns: repeat(7, auto);
            grid-gap: 8px;

            align-items: center;

            color: var(--color-gray);

            span {
              display: flex;

              align-items: center;
              justify-content: center;

              width: 48px;
              height: 52px;

              font-size: var(--font-size-headline4);
              font-weight: 600;
              color: var(--color-white);
              text-align: center;

              border: solid 1px var(--color-gray);
              border-radius: 8px;

              transition: padding 150ms ease, width 150ms ease, height 150ms ease, font-size 150ms ease;
            }
          }
        }
      }
    }
  }
}

@media (--min-xs) {
  .leaderboardTournamentHeader {
    .header {
      .headerBody {
        .container {
          .countdownWrapper {
            .countdown {
              span {
                width: 64px;
                height: 52px;

                font-size: var(--font-size-headline2);
              }
            }
          }
        }
      }
    }
  }
}

@media (--min-md) {
  .leaderboardTournamentHeader {
    margin-bottom: 24px;

    .header {
      .headerBody {
        .container {
          flex-direction: row;

          justify-content: space-between;

          margin-bottom: -24px;

          .game {
            margin-right: 16px;

            .info {
              .name {
                max-width: 30vw;
              }
            }
          }

          .countdownWrapper {
            align-self: flex-end;
          }
        }
      }
    }
  }
}
