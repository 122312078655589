.emptyNotifications {
  display: flex;

  flex-direction: column;

  align-items: center;

  padding: 30px 0;

  svg {
    display: block;

    width: 30%;
    max-width: 240px;
  }

  .description {
    margin-top: 30px;

    font-size: var(--font-size-big);
    font-weight: 700;
  }
}
