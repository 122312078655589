.emptyStateDisplay {
  display: grid;

  grid-gap: 24px;

  align-items: center;
  justify-content: center;

  .icon {
    display: flex;

    align-items: center;
    justify-content: center;

    svg {
      display: block;

      width: 200px;
      max-width: 100%;
      height: auto;
    }
  }

  .message {
    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-gray);
    text-align: center;
  }
}
