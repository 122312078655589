.errors {
  &.textAlignCenter {
    .error {
      text-align: center;
    }
  }

  &.textAlignLeft {
    .error {
      text-align: left;
    }
  }

  .error {
    padding: 4px 0;

    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-red);
  }
}
