.callToAction {
  .container {
    display: flex;

    flex-direction: column;

    align-items: center;

    padding: var(--section-vertical-padding);

    color: var(--color-white);
    text-align: center;

    .title {
      margin-bottom: 8px;

      font-size: var(--font-size-headline2);
    }

    .content {
      max-width: 640px;

      font-size: var(--font-size-small);
      font-weight: 600;

      color: var(--color-gray);
    }

    .button {
      width: 300px;
      margin-top: 30px;
    }
  }
}

@media (--min-sm) {
  .callToAction {
    .container {
      .button {
        width: 180px;
      }
    }
  }
}

@media (--min-md) {
  .callToAction {
    .container {
      .title {
        font-size: var(--font-size-headline1);
      }
    }
  }
}
