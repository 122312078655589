.userPage {
  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 9;

  width: 100vw;
  height: 100%;

  overflow: auto;

  background-color: var(--color-black);

  transition: transform 150ms ease;

  transform: translateY(0);

  &:global {
    &.enter, &.exit.exit-active {
      transform: translateY(100%);
    }

    &.enter.enter-active, &.exit {
      transform: translateY(0);
    }
  }

  .user {
    .tabs {
      margin: 30px 0;

      > * {
        display: grid;

        grid-gap: 24px;
      }
    }
  }
}

@media (--min-lg) {
  .userPage {
    .user {
      .tabs {
        .general {
          display: flex;

          .col {
            flex: 1;

            > :not(:first-child) {
              margin-top: 24px;
            }

            &:nth-child(2) {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
}
