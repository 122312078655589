.partners {
  margin: 30px 0;

  .wrapper {
    display: grid;

    grid-template-columns: auto;
    grid-row-gap: 36px;
    grid-column-gap: 48px;

    align-items: center;
    justify-content: center;

    .partner {
      a {
        &[data-focus-visible-added], &:hover {
          svg {
            opacity: 1;
          }

          img {
            margin: 0 auto;

            filter: brightness(2);
          }
        }

        .icon {
          display: block;

          height: 35px;

          margin: 0 auto;

          filter: contrast(1) grayscale(1);

          opacity: 0.5;
        }
      }
    }
  }
}

@media (--min-xs) {
  .partners {
    .wrapper {
      grid-template-columns: repeat(2, auto);
    }
  }
}
