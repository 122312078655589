.signUpOrSignIn {
  padding: 25px 20px;
  margin: 0 auto;

  background-color: var(--color-black);

  border: 2px solid var(--color-dark-gray2);
  border-radius: 16px;

  box-shadow: 0 0 17px rgba(0, 0, 0, 0.5);

  .header {
    display: grid;

    grid-auto-flow: row;
    grid-gap: 14px;

    .title {
      padding: 0 10px;

      text-align: center;

      svg {
        display: inline-block;

        height: 1em;

        vertical-align: text-bottom;
      }
    }

    .description {
      font-weight: 600;
      color: var(--color-gray);

      text-align: center;
    }

    .stateButton {
      font-size: var(--font-size);
      font-weight: 600;
      color: var(--color-red);

      text-decoration: none;

      cursor: pointer;

      &[data-focus-visible-added], &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttonWrapper {
    display: flex;

    flex-direction: column;

    align-items: center;

    margin-top: 20px;
  }
}

@media (--min-lg) {
  .signUpOrSignIn {
    .header {
      .title {
        font-size: var(--font-size-headline2);
      }

      .description {
        font-size: var(--font-size);
      }
    }
  }

}
