.duelRules {
  .rules {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &.active {
      .header {
        border-radius: 8px 8px 0 0;

        svg {
          width: 18px;
          height: 18px;

          transform: rotate(45deg);
        }
      }
    }

    .header {
      display: flex;

      align-items: center;
      justify-content: space-between;

      width: 100%;

      padding: 14px 16px;

      font-size: var(--font-size);
      font-weight: 700;

      color: var(--color-white);
      text-align: left;

      cursor: pointer;

      background-color: var(--color-dark-gray);

      border-radius: 8px;

      transition: background-color 100ms ease;

      svg {
        width: 17px;
        height: 17px;

        margin-left: 30px;

        fill: var(--color-gray);

        transition: transform 150ms ease;

        transform: rotate(0);
      }

      &[data-focus-visible-added], &:hover, &:active {
        background-color: var(--color-dark-gray2);
      }
    }

    .content {
      padding: 20px 24px;
      margin-top: 2px;

      font-size: var(--font-size);
      font-weight: 500;
      color: var(--color-white2);

      background-color: var(--color-black3);

      border-radius: 0 0 8px 8px;

      .markdown {
        h3 {
          font-size: var(--font-size-headline4);
          text-indent: 33px;

          background-repeat: no-repeat;
          background-position: left center;
          background-size: 25px 25px;

          &:nth-of-type(1) {
            background-image: url('./challenge.svg');
          }

          &:nth-of-type(2) {
            background-image: url('./sword.svg');
          }

          &:nth-of-type(3) {
            background-image: url('./throphy.svg');
          }

          &:nth-of-type(4) {
            background-image: url('./warning.svg');
          }
        }

        p {
          margin-left: 33px;
        }

        ul {
          margin-left: 30px;

          li {
            text-indent: 17px;
          }
        }
      }
    }
  }
}
