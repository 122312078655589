.depositCurrencyRate {
  position: relative;

  display: grid;

  grid-template-columns: 1fr auto 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 10px;

  align-items: center;
  justify-items: center;

  padding: 36px 10px;

  background-color: var(--color-black3);
  border-radius: 14px;

  &.ramadanCampaign {
    grid-template-columns: 1fr;

    .equality {
      display: none;
    }

    .ramadanCampaign {
      grid-column: 1 / -1;

      padding: 0 24px;

      .title {
        margin-bottom: 8px;

        text-align: center;
      }

      .disclaimer {
        font-size: var(--font-size-small);

        color: var(--color-gray);
      }
    }

    .box {
      &.coin {
        .amount {
          text-decoration: line-through;
        }

        .discountAmount {
          color: var(--color-green);
          text-align: center;
        }
      }
    }

    .discount {
      position: absolute;

      top: -16px;
      right: -12px;

      display: flex;

      align-items: center;
      justify-content: center;

      width: 56px;
      height: 56px;

      padding-bottom: 2px;

      font-size: var(--font-size-big);
      font-weight: 700;

      line-height: 1;
      color: var(--color-white);
      text-align: center;

      background-color: var(--color-green);
      border-radius: 50%;

      transition: right var(--transition-duration) ease;
    }
  }

  .box {
    &.coin {
      .amount {
        text-decoration: line-through;
      }

      .discountAmount {
        color: var(--color-green);
      }
    }

    .muted {
      font-size: var(--font-size);
      font-weight: 600;
      color: var(--color-gray);
      text-align: center;
    }

    .amount {
      font-size: 24px;
      font-weight: bold;
      color: var(--color-white);
      text-align: center;
    }
  }

  .equality {
    display: flex;

    align-content: center;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    background-color: var(--color-green2);
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    svg {
      display: block;

      width: 24px;
      height: 100%;
    }
  }
}

@media (--min-xs) {
  .depositCurrencyRate {
    &.ramadanCampaign {
      grid-template-columns: 1fr auto 1fr;

      .equality {
        display: flex;
      }
    }
  }
}
