.leaderboardTournamentRank {
  &.me {
    .user {
      .inline {
        color: var(--color-red);
      }
    }

    .rankCount {
      color: var(--color-red);
    }
  }

  .trophies {
    svg {
      display: block;

      width: auto;
      height: 24px;

      margin: 0 auto;
    }
  }

  .user {
    display: grid;

    grid-template-columns: min-content;
    
    .inline {
      display: grid;

      grid-template-columns: repeat(2, min-content);
      grid-gap: 8px;

      align-items: center;

      font-size: var(--font-size-small);
      font-weight: 600;
      color: var(--color-white);

      text-decoration: none;

      transition: text-decoration 150ms ease;

      &[data-focus-visible-added], &:hover {
        text-decoration: underline;

        img {
          transition: transform 150ms ease;
          transform: scale(1.2);
        }
      }
    }
  }

  .count {
    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-white);

    .inline {
      display: flex;

      align-items: center;

      font-size: var(--font-size);
      font-weight: 600;
      color: var(--color-white);

      svg {
        display: block;

        width: 16px;
        height: 16px;

        margin-right: 6px;
      }
    }
  }

  .rankCount {
    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-gray);
  }

  .reward {
    font-size: var(--font-size);
    font-weight: 600;
    color: var(--color-white);

    vertical-align: middle;

    .empty {
      text-align: center;
    }

    svg {
      display: inline-block;

      width: auto;
      height: 16px;

      margin-right: 4px;
    }
  }
}
