.filterSortLayout {
  position: relative;

  display: flex;

  flex: 1;

  flex-direction: column;

  min-height: 100%;

  .toggle {
    position: fixed;
    top: calc(64px + 32px);
    right: 0;

    display: flex;

    flex-direction: row;

    gap: 8px;

    align-items: center;
    justify-content: center;

    padding: 16px;

    font-size: 12px;
    font-weight: 600;
    color: var(--color-gray);

    cursor: pointer;

    background-color: var(--color-black);

    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.53);

    transition: right 150ms ease, top 150ms ease;

    svg {
      display: block;

      width: auto;
      height: 12px;

      &.closed {
        fill: var(--color-red);
      }

      &.opened {
        fill: var(--color-gray);

        transform: rotate(180deg);
      }
    }

    &[data-focus-visible-added], &:hover {
      text-decoration: underline;

      svg {
        &.closed {
          filter: drop-shadow(0 0 2px var(--color-red));
        }

        &.opened {
          filter: drop-shadow(0 0 2px var(--color-gray));
        }
      }
    }

    &.open {
      right: var(--sidebar-right-width);
    }
  }

  .verticalFilterSort {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;

    width: var(--sidebar-right-width);
    min-height: 100%;

    transition: right 150ms ease, top 150ms ease;

    &.closed {
      right: calc(var(--sidebar-right-width) * -1);
    }
  }

  .main {
    width: 100%;

    transition: width 150ms ease;

    &.shrink {
      width: calc(100% - 240px);
    }
  }
}

@media (--min-md) {
  .filterSortLayout {
    .toggle {
      top: calc(80px + 32px);
    }

    .verticalFilterSort {
      top: 80px;
    }
  }
}
