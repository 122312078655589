.dateTimeCheckModal {
  text-align: center;

  .visual {
    display: flex;

    flex-direction: column;

    align-items: center;

    margin-bottom: 20px;

    svg {
      display: block;

      width: 100px;
      max-width: 50%;
    }
  }

  .body {
    font-size: var(--font-size-big);
    font-weight: 600;

    .values {
      margin-top: 20px;
    }
  }
}
