.header {
  background-color: var(--color-black);
  
  :global(.layout-with-sidebar) & {
    .container {
      .logoWrapper {
        display: none;
      }
    }
  }

  .container {
    display: flex;

    flex-direction: row;

    align-items: center;

    height: 64px;

    > :not(:last-child) {
      margin-right: 10px;
    }

    .separator {
      flex: 1;

      min-width: 20px;
    }

    .languageSelector {
      margin-right: 16px;
    }

    .logoWrapper {
      display: flex;

      flex-direction: column;

      justify-content: center;

      padding-right: 20px;
    }

    .logo {
      display: block;

      height: 20px;
    }
  }
}

@media (--min-md) {
  .header {
    .container {
      height: 80px;
    }
  }
}
