.notificationMenu {
  position: relative;

  .headerButton {
    svg {
      display: block;

      height: 24px;
    }
  }

  .notificationIndicator {
    position: absolute;
    top: -15%;
    right: 30%;
    z-index: 2;

    width: 16px;

    height: 16px;

    font-size: var(--font-size-smaller);
    font-weight: 700;
    text-align: center;

    background-color: var(--color-red);

    border-radius: 50%;

    transform: translate(50%, 50%);
  }

  .box {
    position: fixed;

    top: 80px;
    right: 5vw;
    z-index: 11;

    display: flex;

    flex-direction: column;

    width: 90vw;
    max-height: calc(80vh - 80px);

    transition: opacity 300ms ease, transform 300ms ease;

    &:global {
      &.enter, &.exit.exit-active {
        opacity: 0;

        transform: translateY(-20px);
      }

      &.enter.enter-active, &.exit {
        opacity: 1;

        transform: translateY(0);
      }
    }

    .header {
      display: flex;

      flex-direction: row;

      align-items: center;
      justify-content: space-between;

      padding: 10px 16px;

      background-color: var(--color-dark-gray);

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .title {
        font-size: var(--font-size-big);
        font-weight: 700;
      }

      .markAllAsReadButton {
        font-size: var(--font-size-small);
        font-weight: 700;

        color: var(--color-white);
        text-decoration: none;

        cursor: pointer;

        &[data-focus-visible-added], &:hover {
          text-decoration: underline;
        }
      }
    }

    .body {
      flex: 1 1 auto;

      padding: 10px 16px;

      overflow: auto;

      background-color: var(--color-black3);
    }

    .footer {
      display: flex;

      flex-direction: row;

      justify-content: center;

      padding: 10px 16px;

      background-color: var(--color-dark-gray);

      border-bottom-right-radius: 8px;

      border-bottom-left-radius: 8px;

      .pageLink {
        font-size: var(--font-size-small);
        font-weight: 700;

        color: var(--color-white);
        text-decoration: none;

        &[data-focus-visible-added], &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (--min-md) {
  .notificationMenu {
    .box {
      width: 400px;
    }
  }
}
