.avatarUpdateModal {
  .errors {
    margin-bottom: 20px;
  }

  .inputWrapper {
    position: relative;

    .inputButton {
      display: flex;

      flex-direction: row;

      align-items: center;

      justify-content: center;

      width: 100%;

      padding: 16px 20px;

      font-size: var(--font-size-bigger);
      font-weight: 600;

      color: var(--color-white);
      text-align: center;

      border: 2px dashed var(--color-dark-gray3);
      border-radius: 8px;

      transition: background-color 100ms ease;

      svg {
        display: block;

        height: 24px;

        margin-right: 20px;

        fill: var(--color-white);
      }

      &[data-focus-visible-added], &:hover {
        background-color: var(--color-dark-gray2);
      }
    }

    .input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      width: 100%;

      pointer-events: none;

      opacity: 0;
    }
  }
}
