.userGames {
  .title {
    margin-bottom: 20px;

    font-weight: 700;
    color: var(--color-white);

    .count {
      font-size: var(--font-size);
      font-weight: 500;
      color: var(--color-gray2);
    }
  }

  .body {
    display: grid;

    grid-gap: 10px;
  }

  .warning {
    margin-top: 20px;

    font-size: var(--font-size-small);
    font-weight: 600;
    color: var(--color-gray);
    text-align: center;
  }
}
