.duelCancel {
  .wrapper {
    display: flex;

    align-items: center;
    justify-content: space-around;

    padding: 24px 20px;

    background-color: var(--color-black3);

    border-radius: 8px;

    .message {
      margin-right: 10px;

      font-size: var(--font-size-small);
      font-weight: 500;
      color: var(--color-gray);
    }
  }
}
