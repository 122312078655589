.userHeader {
  position: relative;

  .background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 210px;

    background: url('./bg.png') no-repeat bottom center;
    background-size: cover;
  }

  .actions {
    position: relative;

    display: grid;

    grid-template-columns: repeat(2, auto);

    grid-gap: 30px;

    align-items: center;
    justify-content: space-between;

    padding: 12px 16px;

    .goBack, .edit {
      font-size: var(--font-size);
      font-weight: 700;
      color: var(--color-gray);
      text-align: right;
      text-decoration: none;

      cursor: pointer;

      &.edit {
        display: grid;

        grid-template-columns: repeat(2, auto);
        grid-gap: 5px;

        align-items: center;
        justify-content: flex-end;

        text-align: right;
      }

      &[data-focus-visible-added], &:hover {
        text-decoration: underline;

        svg {
          filter: drop-shadow(0px 2px 4px var(--color-gray));
        }
      }

      svg {
        display: block;

        height: 16px;
      }
    }
  }

  .wrapper {
    position: relative;

    display: grid;

    grid-gap: 26px;

    padding: 12px 16px;

    .user {
      display: flex;

      flex-direction: column;

      align-items: center;
      justify-content: center;

      text-align: center;

      .avatar {
        margin-bottom: 10px;
      }

      .username {
        display: grid;

        grid-template-columns: repeat(2, auto);
        grid-gap: 8px;

        align-items: center;
        justify-content: center;

        margin-bottom: 4px;

        font-weight: 700;
        color: var(--color-white);

        .share {
          margin-left: 12px;

          cursor: pointer;

          svg {
            display: inline-block;

            height: 18px;
          }

          &[data-focus-visible-added], &:hover {
            text-decoration: underline;

            svg {
              filter: drop-shadow(0px 2px 4px var(--color-gray));
            }
          }
        }
      }

      .date {
        font-size: var(--font-size-small);
        font-weight: 500;
        color: var(--color-gray);
      }
    }

    .details {
      display: grid;

      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
      grid-gap: 32px;

      .stats {
        display: grid;

        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        align-items: center;
        align-self: flex-end;
        justify-content: space-evenly;

        .stat {
          font-size: var(--font-size-headline4);
          font-weight: 600;
          color: var(--color-white);
          text-align: center;

          :last-child {
            font-size: var(--font-size-small);
            font-weight: 500;
            color: var(--color-gray);
          }

          .muted {
            font-size: var(--font-size-headline4);
            font-weight: 600;
            color: var(--color-gray);
            text-align: center;
          }
        }
      }

      .button {
        display: grid;

        grid-template-columns: repeat(1, minmax(min-content, 250px));

        align-self: flex-start;
        justify-content: center;
      }
    }
  }
}

@media (--min-lg) {
  .userHeader {
    .bg {
      height: 80%;
    }

    .actions {
      padding: 22px 24px;
    }

    .wrapper {
      grid-template-columns: 3fr 4fr;

      padding: 22px 24px;
    }
  }
}
