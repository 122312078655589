.inlineLabel {
  font-size: var(--font-size);
  font-weight: 600;

  cursor: pointer;

  &.withImage {
    display: inline-flex;

    align-items: center;

    img {
      display: block;

      width: 32px;
      height: 24px;

      margin-right: 10px;

      &.imageRadius {
        height: 32px;

        margin-right: 8px;

        border-radius: 50%;
      }
    }
  }

  a {
    font-weight: 700;
    color: var(--color-red);
    text-decoration: none;

    &[data-focus-visible-added],
    &:hover {
      text-decoration: underline;
    }
  }
}
