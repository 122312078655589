.withdrawCurrencyRate {
  position: relative;

  display: grid;

  grid-gap: 12px;

  .inline {
    display: grid;

    grid-template-columns: 1fr auto 1fr;
    grid-gap: 12px;

    align-items: center;

    padding: 24px 16px;

    background-color: var(--color-black3);
    border-radius: 14px;

    .separator {
      align-self: stretch;

      width: 2px;

      border: dashed 1px var(--color-gray);
      opacity: 0.5;
    }

    .info {
      padding: 0;

      .amount {
        color: var(--color-white);
      }
    }
  }

  .info {
    padding: 24px 16px;

    text-align: center;

    background-color: var(--color-black3);
    border-radius: 14px;

    .muted {
      font-size: var(--font-size);
      font-weight: 600;
      color: var(--color-gray);
    }

    .amount {
      font-weight: bold;
      color: var(--color-green2);
    }
  }

  .equality {
    position: absolute;

    top: 50%;
    right: 0;
    left: 0;

    display: flex;

    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    margin: 0 auto;

    background-color: var(--color-green2);
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    transform: translateY(-50%);

    svg {
      display: block;

      width: 12px;
      height: auto;
    }
  }
}
