.pageHeader {
  display: flex;

  flex-direction: column;

  min-height: 280px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.backgroundStatic {
    background-position: top center;
  }

  &.backgroundCouponRedemption {
    background-image: url("./redeem-coupon.jpg");
  }

  &.backgroundDuelsListing {
    background-image: url("./duels-listing.png");
  }

  &.backgroundGameStatistics {
    background-image: url("./password-reset.jpg");
  }

  &.backgroundPasswordRecovery {
    background-image: url("./password-recovery.jpg");
  }

  &.backgroundPasswordReset {
    background-image: url("./password-reset.jpg");
  }

  &.backgroundSettings {
    background-image: url("./settings.jpg");
  }

  &.backgroundWallet {
    background-image: url("./wallet.png");
  }

  .body {
    display: flex;

    flex: 1;
    flex-direction: column;

    align-items: center;

    justify-content: center;

    padding: 20px;

    text-align: center;

    background: linear-gradient(to bottom, rgba(17, 18, 23, 0.5), var(--color-black) 100%);

    .title {
      max-width: 500px;
    }

    .description {
      max-width: 500px;

      margin-top: 20px;

      font-size: var(--font-size-big);
      font-weight: 600;
      color: var(--color-gray);
    }
  }
}
