.signIn {
  max-width: 540px;

  .error {
    margin: 16px 0;

    font-size: var(--font-size-small);
    font-weight: 500;

    color: var(--color-red);
    text-align: center;
  }

  .rememberMeFormGroup {
    display: flex;

    flex-direction: row;

    justify-content: center;
  }

  .postForm {
    margin-top: 20px;

    text-align: center;

    .postFormLink {
      font-size: var(--font-size-small);
      font-weight: 600;

      color: var(--color-red);

      text-decoration: none;

      &[data-focus-visible-added], &:hover {
        text-decoration: underline;
      }
    }
  }
}
