.leaderboardTournamentDescription {
  background-color: var(--color-black3);
  border-radius: 8px;

  .header {
    padding: 12px 10px;

    font-weight: 700;
    color: var(--color-white);

    background-color: var(--color-dark-gray);

    border-radius: 8px 8px 0 0;

    transition: padding 150ms ease;
  }

  .body {
    padding: 24px 10px;

    font-size: var(--font-size-small);
    font-weight: 600;
    line-height: 1.5;
    color: var(--color-gray);

    transition: padding 150ms ease;
  }
}

@media (--min-xs) {
  .leaderboardTournamentDescription {
    .header {
      padding: 12px 32px;
    }

    .body {
      padding: 24px 32px;
    }
  }
}
