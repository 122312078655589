.leaderboardTournamentTopRank {
  display: flex;

  flex: 1;

  justify-content: space-between;

  padding: 16px 10px 24px 10px;

  text-decoration: none;

  background-color: var(--color-black3);
  border-radius: 8px;

  transition: padding 150ms ease;

  &[data-focus-visible-added], &:hover {
    background-color: var(--color-dark-gray2);
  }

  .user {
    display: flex;

    .details {
      display: flex;

      flex-direction: column;

      justify-content: space-around;

      margin-left: 10px;

      font-size: var(--font-size);
      font-weight: 700;
      color: var(--color-white);

      .username {
        max-width: 150px;
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .count {
      display: grid;

      grid-template-columns: repeat(2, auto);
      grid-gap: 10px;

      align-items: center;
      justify-content: flex-start;

      .info {
        display: flex;

        flex-wrap: nowrap;

        align-items: center;
      }

      svg {
        display: inline-block;

        width: 16px;
        height: 16px;

        margin-right: 6px;
      }
    }
  }

  .reward {
    display: flex;

    align-items: center;

    margin-left: 20px;

    font-size: var(--font-size);
    font-weight: 600;
    color: var(--color-white);

    svg {
      display: block;

      width: auto;
      height: 16px;

      margin-right: 6px;
    }
  }
}

@media (--min-xs) {
  .leaderboardTournamentTopRank {
    padding: 16px 32px 24px 32px;
  }
}
