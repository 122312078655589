.activityIndicator {
  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 40px;

  &.flex {
    flex: 1;
  }

  &.takeOver {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .wrapper {
    position: relative;

    display: inline-block;

    width: 30px;
    height: 30px;

    border: 4px solid var(--color-white);

    animation: loader 2s infinite ease;

    .inner {
      display: inline-block;

      width: 100%;

      vertical-align: top;

      background-color: var(--color-white);

      animation: loader-inner 2s infinite ease-in;
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0;
  }

  25% {
    height: 0;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0;
  }
}
