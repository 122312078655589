.gameStatisticsRanking {
  .isEmpty {
    display: flex;

    justify-content: center;

    margin: 20px 0;

    font-weight: 600;
    color: var(--color-gray);
    text-align: center;
  }

  .wrapper {
    display: grid;

    grid-gap: 30px;

    .tableWrapper {
      width: 100%;

      overflow-x: auto;

      .table {
        width: 100%;

        text-align: left;

        table-layout: auto;

        border-collapse: collapse;

        tbody {
          tr {
            &:nth-child(odd), &.authUser {
              background-color: var(--color-dark-gray2);

              border-radius: 6px;
            }

            td {
              &:first-child {
                border-radius: 6px 0 0 6px;
              }

              &:last-child {
                border-radius: 0 6px 6px 0;
              }
            }
          }
        }

        tr {
          padding: 10px 0;

          td:first-child, th:first-child {
            padding: 8px 2px 8px 4px;
          }

          td:last-child, th:first-child {
            padding: 8px 4px 8px 2px;
          }

          td, th {
            padding: 8px;

            transition: padding 150ms ease;
          }

          th:last-child, td:last-child, th:first-child, td:first-child {
            text-align: center;
          }
        }
      }
    }

    .showMoreButtonWrapper {
      display: flex;

      justify-content: center;
    }
  }
}

@media (--min-xs) {
  .gameStatisticsRanking {
    .wrapper {
      .tableWrapper {
        .table {
          tr {
            td:first-child, th:first-child {
              padding: 8px 5px 8px 10px;
            }

            td:last-child, th:first-child {
              padding: 8px 10px 8px 5px;
            }

            td, th {
              padding: 8px 5px;
            }
          }
        }
      }
    }
  }
}

@media (--min-sm) {
  .gameStatisticsRanking {
    .wrapper {
      .tableWrapper {
        .table {
          table-layout: fixed;
        }
      }
    }
  }
}
