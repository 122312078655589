.layout {
  display: flex;

  flex-direction: column;

  min-height: 100vh;

  background-color: var(--color-black);

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;

    width: 210px;
  }

  &.withStaticSidebar {
    .sidebar {
      left: 0;
      z-index: 3;
    }

    .body {
      padding-left: 210px;

      .header {
        left: 210px;
      }
    }
  }

  &:not(.withStaticSidebar) {
    .sidebar {
      right: 0;

      z-index: 11;

      transition: opacity 200ms ease, transform 200ms ease;

      &:global {
        &.enter, &.exit.exit-active {
          opacity: 0;

          transform: translateX(100%);
        }

        &.enter.enter-active, &.enter-done, &.exit {
          opacity: 1;

          transform: translateX(0);
        }
      }
    }
  }

  &.footerHidden {
    margin-bottom: 30px;

    .body {
      .main {
        margin-bottom: 80px;
      }
    }
  }

  .body {
    position: relative;

    display: flex;

    flex: 1;

    flex-direction: column;

    .header {
      position: fixed;

      top: 0;
      right: 0;
      left: 0;

      z-index: 8;
    }

    .main {
      display: flex;

      flex: 1;

      flex-direction: column;

      margin-top: 64px;
    }
  }
}

@media (--min-md) {
  .layout {
    .body {
      .main {
        margin-top: 80px;
      }
    }
  }
}

@media (--min-lg) {
  .layout {
    &.footerHidden {
      .body {
        .main {
          margin-bottom: 0;
        }
      }
    }
  }
}
