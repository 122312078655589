.signUp {
  max-width: 540px;

  .grid {
    display: grid;

    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    grid-column-gap: 10px;

    margin: 16px 0;

    .gridFormGroup {
      margin: 0;
    }

    &.gridWide {
      grid-template-columns: 1fr 1fr;
    }
  }

  .button {
    width: 100%;
  }

  .conditions {
    color: var(--color-gray);
  }
}
