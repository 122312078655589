.sideTabs {
  display: flex;

  flex-direction: row;

  overflow: hidden;

  .links {
    position: relative;

    flex: 0 0 auto;

    width: 100%;

    padding: 16px 0;

    transition: left 500ms ease;

    &:global {
      &.enter, &.exit.exit-active {
        left: -100%;
      }

      &.enter.enter-active, &.exit {
        left: 0;
      }
    }
  }

  .body {
    flex: 1;

    padding: 0 15px;
  }
}

@media (--min-lg) {
  .sideTabs {
    .links {
      width: 240px;

      background-color: var(--color-black3);

      border-radius: 8px;
    }

    .body {
      padding: 0 0 0 30px;
    }
  }
}
